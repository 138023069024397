require('./bootstrap');
require('turbolinks').start();


import moment from 'moment-timezone'
import SimpleBar from "simplebar";
import BootstrapVue from 'bootstrap-vue'

import VueTour from 'vue-tour'
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
require('vue-tour/dist/vue-tour.css');

window.Vue = require('vue');
Vue.use(BootstrapVue);
Vue.config.ignoredElements = ['max'];
Vue.use(VueTour);
Turbolinks.setProgressBarDelay(50);

moment.tz.setDefault('Europe/Amsterdam')

document.addEventListener('turbolinks:load', (event) => {

    /**
     * Loading Top bar vue
     */
    Vue.component('loadingTop', {
        data() {
            return {}
        },
        template: '<div class="loading-top" :class="$root.loading.state"><span class="text-bold">{{$root.loading.msg}}</span></div>'
    });
    $('[data-toggle="tooltip"]').tooltip();


    $(document).ready(function () {


        /**
         * Module menu replication
         */

        if ($(window).width() < 576) {
            $("#navbarContent nav.nav-item.sidebar-holder").html($("nav.sidebar-sticky.d-none.d-md-block").html());
        } else if (document.getElementById("sidebar_sticky")) {
            new SimpleBar(document.getElementById("sidebar_sticky"));
        }


        /**
         * Module menu expand
         */
        $(".menu-wrapper ul.nav li.nav-item").hover(function () {
            if ($(this).has("ul")) {
                $(this).find("ul").slideDown("fast");
            }
        });


        $("form:not(.changeForm)[method='POST']").submit(function (e) {
            let btns = $(this).find(".btn.btn-primary");
            let lastbtn = btns[btns.length - 1];
            $(lastbtn).html('<i class="loading-inline active"></i> ' + " Loading...");
        });
        /**
         * Default nav functions
         */
        // $("nav .nav-link").click(function () {
        //     $("#app #loading").addClass("active");
        // });

        $(".default-server-chooser .server-item").click(function (e) {
            e.preventDefault();
            // if (!$(this).hasClass("active")) {
            const btn = $(this);
            const server_id = $(this).data("serverid");
            $("#app #loading").addClass("active");
            axios.patch("/user/@me", {
                default_server: server_id
            })
                .then((res) => {
                    $("#app #loading").removeClass("active");
                    if (btn.hasClass("dropdown-item")) {
                        // $(".btn.btn-sm#dropServerList").html("<i class='material-icons'>dns</i> " + $(".default-server-chooser .server-item[data-serverid='" + server_id + "']").text());
                        // $(".default-server-chooser .server-item").removeClass("active");
                        // $(btn).addClass("active");
                        // location.reload();
                        Turbolinks.visit(window.location);
                    } else if (btn.find(".check-server").length > 0) {
                        // on account page i think - so no reload needed
                        $(".default-server-chooser .server-item").removeClass("active");
                        $(btn).addClass("active");
                        $(".card .servers .alert").remove();
                        $(".card .servers").prepend("<div class='alert alert-success'>" + res.data.success + "</div>");
                        $(".btn.btn-sm#dropServerList").html("<i class='material-icons'>dns</i> " + $(".default-server-chooser .server-item[data-serverid='" + server_id + "'] h6").text());
                        $(".default-server-chooser .server-item[data-serverid='" + server_id + "']").addClass("active");
                    }
                });
            // }
        });

        function login() {
            const loginForm = $("form#login-form");
            var statusEL = $($("form#login-form").parent()).find(".status");
            // clear status
            $(statusEL).html("");
            $("button#login-btn i.loading-inline").addClass("active");


            // be sure that this is a HTTP S REQUEST!!!!
            $.post($(loginForm).attr("action").replace("http://", "https://"), $(loginForm).serializeArray(), null, "json")
                .done(function (response) {
                    // successfull login

                    $(statusEL)
                        .html(
                            '<div class="alert alert-success" role="alert">' +
                            "Ingelogd! Je wordt nu doorgestuurd..." +
                            "</div>"
                        );
                    // reload for redirect to dashboard
                    location.href = response.redirect;
                })
                .fail(function (response) {
                    if (response.responseJSON) {
                        if (response.responseJSON.errors) {
                            let errorData = response.responseJSON.errors;
                            $.each(errorData, function (errorName, el) {
                                $(statusEL).append(
                                    '<div class="alert alert-danger" role="alert">' + el + "</div>"
                                );
                                $(".form-control[type='" + errorName + "']").addClass("is-invalid");
                            });
                            $("button#login-btn i.loading-inline").removeClass("active");
                        }
                    }
                }, "json");
        }

        $("button#login-btn").click((e) => {
            e.preventDefault();
            login();
        });
        $("form#login-form").submit(function (e) {
            e.preventDefault();
            login();
        });
        $(".submit-modal-form").click(function () {
            $("#" + $(this).data("target")).find("button[type='submit']").click();
        });

        if ($("#app.docs").length > 0) {
            setTimeout(() => {
                if ($(window).width() < 786) {
                    $("#app.docs > div > .sidebar").addClass("is-hidden");
                    // $("#app.docs > div > .documentation").addClass("expanded");
                } else {
                    $("#app.docs > div > .sidebar").removeClass("is-hidden");
                    $("#app.docs > div > .documentation").removeClass("expanded");
                }
            }, 10);
        }

    });
});
